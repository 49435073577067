.buttonContainer,
.facebook,
.short {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    width: 345px;
    height: 48px;
    border-radius: 10px;
    flex-shrink: 0;
    border: 0;
    transition: background-color 0.3s ease;
  }

  button:disabled {
    background-color: #757373;
    cursor: not-allowed;
    pointer-events: none;
  }

  button:hover {
    background-color: #757373;
  }
}

.buttonContainer {
  button {
    background: #EFEFEF;
  }
}

.facebook {
  button {
    background-color: #1877F2;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  button:hover {
    background-color: #0a3164;
  }
}

.primary {
  button {
    background-color: #EEB034;
  }

  button:hover {
    background-color: #a4894e;
  }
}

.error {
  button {
    background-color: #151515;
    color: #E81212;
  }

  button:hover {
    background-color: #1e1e1e;
  }
}

.cancel {
  button {
    background-color: #E81212;
    color: #ffff;
  }

  button:hover {
    background-color: #9f0e0e;
  }
}

.varient {
  button {
    background-color: transparent;
    color: #FFD482;
    border-width: 2;
    border-color: #FFD482;
  }

}

.background {
  button {
    background-color: #e7e6e6;
    color: #000000;
  }

  button:hover {
    background-color: #B4B4B4;
  }
}

.short {
  button {
    width: 220px;
    /* Adjust the width as needed */
  }
}

.iconStyle {
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .buttonContainer {
    width: 100%;
    min-width: 43px;
    height: 40px;

    button {
      width: 100%;
    }
  }

  .short {
    button {
      width: 100%;
    }
  }
}