.textInputContainer {
  input {
    background-color: #3d3d3d;
    border-radius: 12px;
    width: 463px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:focus {
      border-radius: 12px;
    }
  }
}

.mobileTextInputContainer {
  input {
    background-color: #3d3d3d;
    border-radius: 12px;
    width: "100%";
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:focus {
      border-radius: 12px;
    }
  }
}

txt {
  outline: none;
  border: none;
}

.passwordInputContainer {
  background-color: #3d3d3d;
  border-radius: 12px;

  input {
    background-color: #3d3d3d;
    border-radius: 12px;
    width: 427px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:focus {
      background-color: #3d3d3d;
    }
  }
}

.mobilePasswordInputContainer {
  background-color: #3d3d3d;
  border-radius: 12px;

  input {
    background-color: #3d3d3d;
    border-radius: 12px;
    width: 223px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &:focus {
      background-color: #3d3d3d;
    }
  }
}
